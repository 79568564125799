import React from 'react'

export default function Download() {
  return (
    <div>
    <div className='main flex items-center justify-evenly lg:py-20 md:py-20 py-10 px-20 
                    max-[414px]:flex-wrap max-[414px]:px-10'>
                        
        <div className="left max-[414px]:order-last">
          <video className='w-[24em] md:w-[20em]'  autoPlay={true} muted>
              <source src='/video/v2.mp4' type='video/mp4' />
          </video>
        </div>
      <div className="right max-[414px]:mb-8 lg:text-right ">
          <h2 className='text-white font-bold lg:text-5xl md:text-4xl text-4xl mb-5'>Download your shows to watch offline</h2>
          <p className='text-white lg:text-2xl md:text-2xl text-2xl '>
              Save your favourites easily and always<br className='hidden lg:block md:block sm:block'/> have something to watch.
          </p>
      </div>
      
    </div>
    <div className="hr_line"></div>
  </div>
  )
}
