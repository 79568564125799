import React from 'react'
import Navbar from './Navbar'
function HeroSection() {
  return (
   <>
    <div
                className=' lg:h-[42em] md:h-[74em] h-[42em]'
                style={{
                  background: "linear-gradient(80deg, black, transparent), url(https://assets.nflxext.com/ffe/siteui/vlv3/ca6a7616-0acb-4bc5-be25-c4deef0419a7/c5af601a-6657-4531-8f82-22e629a3795e/IN-en-20231211-popsignuptwoweeks-perspective_alpha_website_medium.jpg)"
                }}>   
                <Navbar /> 

                <div className="container mx-auto flex justify-center items-center h-96">
                  <div >
                    <h1 className='text-white mb-5 lg:text-5xl md:text-5xl text-3xl px-5 font-bold'>The biggest Indian hits. The best Indian stories. All streaming here.</h1>
                    <p className='text-white lg:text-2xl md:text-2xl text-xl mb-5'>Watch anywhere. Cancel anytime.</p>
                    <p className='text-white  lg:text-xl md:text-xl text-lg mb-5'>Ready to watch? Enter your email to <br className="lg:hidden md:hidden" />create or restart your membership.</p>
                    <div className='flex justify-center items-center space-x-2 flex-col md:flex-row lg:flex-row'>
                      <input type='text' className='bg-[#1d1e1e] text-white text-lg py-3 px-3 lg:w-96 md:w-96 rounded-md 
                      opacity-80 border border-gray-300 hover:ring-1 hover:ring-gray-50 outline-none' placeholder='Email address'/>
                      <button type='button' className="flex items-center gap-3 mt-1 lg:mt-0 md:mt-0 py-3 lg:px-5 md:px-5 px-2 rounded-lg" style={{background: "rgb(229, 9, 20)"}}>
                        <p className='text-white font-bold text-xl'>Get Started</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white ">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
    </div>
    <div className="hr_line"></div>
   </>
  )
}

export default HeroSection