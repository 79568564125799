import Home from './pages/Home';

import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Signin from './pages/registration/Signin';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<Signin />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
