import React from 'react'

export default function Signin() {
  return (
    <>
    <div style={{
                  background: "linear-gradient(80deg, black, transparent), url(https://assets.nflxext.com/ffe/siteui/vlv3/93da5c27-be66-427c-8b72-5cb39d275279/94eb5ad7-10d8-4cca-bf45-ac52e0a052c0/IN-en-20240226-popsignuptwoweeks-perspective_alpha_website_medium.jpg)"
                }}  >
            <div className="main flex justify-center items-center h-screen ">
                <div className='sub-main bg-black bg-opacity-60 px-8 py-16 rounded-md '>
                    <h2 className='font-bold mb-5'>Sign In</h2>
                    <div className='email mb-7'>
                        <input type='text' placeholder='Email address' 
                        className='w-72 px-3 py-3 rounded bg-[#333333] outline-none ' />
                    </div>

                    <div className='pwd mb-7'>
                        <input type='password' placeholder='Password' 
                        className='w-72 px-3 py-3 rounded bg-[#333333] outline-none ' />
                    </div>

                    <div className="sign-in-cta ">
                        <button className=' bg-[#e50815] hover:bg-[#d80c1a] w-72 text-white px-5 py-3 rounded-md font-bold'>
                            Sign In
                        </button>
                    </div>

                    <div className='w-72'>
                    <div className='main flex justify-between items-center mt-2 '>
                        <div className='flex items-center gap-1'>
                            <input type='checkbox' className='w-4 h-4' />
                            <label className='font-light text-gray-400 text-sm '>Remember me</label>
                        </div>
                        <p className='font-light text-gray-400 text-sm '>Need help?</p>
                    </div>

                    <div className="mt-7">
                        <p className='text-gray-400 text-light text-left'>New to Netflix?&nbsp;
                            <span className='text-white hover:underline cursor-pointer'>Sign up now</span>.
                        </p>
                    </div>

                    <div className='mb-2 mt-2'>
                        <p className='text-xs text-gray-400 text-left'><span>This page is protected by Google reCAPTCHA to ensure you're not a bot.</span>&nbsp;
                        <button className='text-blue-600' >
                            Learn more.
                        </button>
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        
    </div>
    </>
  )
}
