import React, { useState } from 'react'

export default function Faq({title, desc}) {
    const [open, setOpen] = useState(false);

  return (
    <div className='faq-main container mx-auto lg:max-w-6xl md:max-w-6xl mb-2 px-5'>
        <div className="">
            <div className="">
                <div className="main top bg-[#2d2d2d] hover:bg-[#414141] 
                flex justify-between text-center px-5 py-4 cursor-pointer"
                onClick={()=> setOpen(!open)}>
                    <div className="left">
                        <h1 className='lg:text-3xl md:text-2xl text-xl '>{title}</h1>
                    </div>
                    <div className="right">
                        {open ? <p className='text-4xl max-[414px]:text-2xl'>x</p> : <p className='text-4xl max-[414px]:text-2xl'>+</p>}
                    </div>
                </div>

                {open && 
                <div className="main bottom bg-[#2d2d2d] px-5 py-4 my-[1px] text-left ">
                <p className='text-3xl max-[414px]:text-2xl'>
                    {desc}
                </p>
                </div> }



            </div>
        </div>
    </div>
  )
}
