import React from 'react'
import {Link} from 'react-router-dom';
function Navbar() {
  return (
    <div>
        <div className='main flex justify-between lg:justify-around md:justify-around py-5 px-5 lg:mb-28 md:mb-28 mb-10'> 
        <div className="left">
            <div className="netflix_logo">
                <img className='w-28 lg:w-44 md:w-44' src="https://upload.wikimedia.org/wikipedia/commons/7/7a/Logonetflix.png"
                            alt="logo" />
            </div>
        </div>

        <div className=''>
            <div className="main flex items-center">
            <div className=" bg-[#111112] text-white mx-4 
                        lg:px-5 md:px-5 px-3 py-1 rounded-md border-[1.5px] border-gray-600 
                        hover:ring-2 hover:ring-gray-200 opacity-80 ">
                     <select name='' id='' className='bg-transparent cursor-pointer outline-none'>
                        <option value="English" className=' bg-[#111112]  outline-none text-white'>English</option>
                        <option value="Tamil" className=' bg-[#111112] text-white  outline-none' >Tamil</option>
                    </select>
            </div>

            <Link to={'/signin'} className=' bg-[#e50815] hover:bg-[#d80c1a] rounded-lg'>
            <button type='button' className=' bg-[#e50815] hover:bg-[#d80c1a] text-white px-5 py-1.5 rounded-lg font-bold'>
            Sign In
            </button>
            </Link>
            </div>
        </div>

        </div>
        
    </div>
  )
}

export default Navbar