import React from 'react'

export default function Watch() {
  return (
    <div>
      <div className='main flex items-center justify-evenly lg:py-20 md:py-20 py-10 px-20 
                      max-[414px]:flex-wrap max-[414px]:px-10'>
        <div className="left max-[414px]:mb-8 lg:text-left ">
            <h2 className='font-bold lg:text-5xl md:text-4xl text-4xl mb-5'>Watch everywhere</h2>
            <p className='lg:text-2xl md:text-2xl text-2xl '>
                Stream unlimited movies and TV shows<br className='hidden lg:block md:block sm:block'/> on your phone, tablet, laptop, and TV.
                </p>
        </div>
        <div className="right">
            <video className='lg:w-[35em] md:w-[35em]'  autoPlay={true} muted>
                <source src='/video/v3.mp4' type='video/mp4' />
            </video>
        </div>
      </div>
      <div className="hr_line"></div>
    </div>
  )
}
