import React from 'react'

export default function Footer() {
  return (
    <div>
        <div className="container mx-auto max-2-6xl py-20 max-[600px]:py-10 px-5">
        <p className='text-gray-300 mb-5 text-left'>Questions? Call <a href="tel:000-800-919-1694"><u>000-800-919-1694</u></a></p>

        <div className=" text-gray-300 grid-container gap-20 max-[600px]:gap-0  mb-4 ">
            <div className="one">
                <ul>
                    <li>FAQ</li>
                    <li>Investor Relations</li>
                    <li>Privacy</li>
                    <li>Speed Test</li>
                </ul>
            </div>
            <div className="two">
            <ul>
                   <li>Help Centre</li>
                    <li>Jobs</li>
                    <li>Cookie Preferences</li>
                    <li>Legal Notices</li>
                </ul>
            </div>
            <div className="three">
            <ul>
                    <li>Account</li>
                    <li>Ways to Watch</li>
                    <li>Corporate Information</li>
                    <li>Only on Netflix</li>
                </ul>
            </div>
            <div className="four">
            <ul>
                    <li>Media Centre</li>
                    <li>Terms of Use</li>
                    <li>Contact Us</li>
                    
                </ul>
            </div>
        </div>


        <div className=" bg-[#111112] text-white footer-select-option 
                        lg:px-5 md:px-5 px-3 py-1 rounded-md border-[1.5px] border-gray-600 
                        hover:ring-2 hover:ring-gray-200 opacity-80 ">
                     <select name='' id='' className='bg-transparent cursor-pointer outline-none '>
                        <option value="English" className=' bg-[#111112]  outline-none text-white'>English</option>
                        <option value="Tamil" className=' bg-[#111112] text-white  outline-none' >Tamil</option>
                    </select>

                   
        </div>
        <div className='mt-4 mb-4 text-left ' ><p >Netflix India</p></div>

        


        </div>
    </div>
  )
}
