import React from 'react'
import HeroSection from '../components/HeroSection'
import Enjoy from '../components/Enjoy'
import Download from '../components/Download'
import Watch from '../components/Watch'
import CreateProfile from '../components/CreateProfile'
import {faqData} from '../data/faqData'
import Faq from '../components/Faq'
import Footer from '../components/Footer'

function Home() {
  return (
    <div>
      <HeroSection />
      <Enjoy />
      <Download />
      <Watch />
      <CreateProfile />

      <div className="faq mb-10">
        <h2 className='text-center text-5xl max-[414px]:text-3xl font-bold py-10 max-[414px]:px-10'>Frequently Asked Questions</h2>
        {faqData.map((item, index)=> {
          const {title, desc} = item ;
          return(
            <Faq key={index} title={title} desc={desc} />
          )
        }) }
       
      </div>
      <div className='lg:mb-14 md:mb-12 mb-10'>
      <p className='text-white  lg:text-xl md:text-xl text-lg mb-5'>Ready to watch? Enter your email to <br className="lg:hidden md:hidden" />create or restart your membership.</p>
                    <div className='flex justify-center items-center space-x-2 flex-col md:flex-row lg:flex-row'>
                      <input type='text' className='bg-[#1d1e1e] text-white text-lg py-3 px-3 lg:w-96 md:w-96 rounded-md 
                      opacity-80 border border-gray-300 hover:ring-1 hover:ring-gray-50 outline-none' placeholder='Email address'/>
                      <button type='button' className="flex items-center gap-3 mt-1 lg:mt-0 md:mt-0 py-3 lg:px-5 md:px-5 px-2 rounded-lg" style={{background: "rgb(229, 9, 20)"}}>
                        <p className='text-white font-bold text-xl'>Get Started</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white ">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                      </button>
                    </div>
      </div>
      <div className="hr_line"></div>

      <Footer />

    </div>
  )
}

export default Home